<template>
  <div>
    <b-card no-body class="mb-0">

      <!-- TABLE -->
      <app-data-table
        :items="organizationHelicopters"
        :fields="organizationHelicoptersFields"
        :busy="organizationHelicoptersLoading"
        :sort-by.sync="sort.sortBy"
        :sort-desc.sync="sort.sortDesc"
        :per-page="numberOfItemsPerPage"
        :small="$store.getters['app/lgAndDown']"
        :top-table="{
          showAdd: $can('ORGANIZATION_ACCOUNT_HELICOPTER_ADD'),
          showSearch: $can('ORGANIZATION_ACCOUNT_HELICOPTER_VIEW'),
          searchText: searchQuery,
        }"
        :actions-table="{
          showDuplicate: $can('ORGANIZATION_ACCOUNT_HELICOPTER_ADD'),
          showEdit: $can('ORGANIZATION_ACCOUNT_HELICOPTER_EDIT'),
          showDelete: $can('ORGANIZATION_ACCOUNT_HELICOPTER_DELETE'),
        }"
        :bottom-table="{ totalCount: pagination.totalItems }"
        @table-search-text=";(searchQuery = $event), fetchOrganizationHelicopters(true)"
        @table-add="changeTypeForm('add')"
        @edit="changeTypeForm('edit', $event.item)"
        @duplicate="changeTypeForm('duplicate', $event.item)"
        @delete="deleteOrganizationHelicopter($event.item)"
      >
        <!-- LOADING -->
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle" />
          </div>
        </template>
        <!--status-->
        <template #cell(active)="data">
          <b-badge v-if="data.item.active" pill variant="light-success">
            {{ $t('common.active') }}
          </b-badge>
          <b-badge v-else pill variant="light-danger">
            {{ $t('common.inactive') }}
          </b-badge>
        </template>
      </app-data-table>

      <!--SIDEBAR-->
      <side-bar-organization-helicopter
        v-model="organizationHelicopterMode"
        :organization-helicopter="organizationHelicopterData._attributes"
        :organization-id="selectedOrganization.id"
        @refresh-organization-helicopters="fetchOrganizationHelicopters(true)"
      />
    </b-card>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import AppDataTable from '@/components/AppDataTable.vue'
import SideBarOrganizationHelicopter from '@/views/organization-helicopter/SideBarOrganizationHelicopter.vue'
import { deleteOrganizationHelicopterRequest } from '@/request/globalApi/requests/organizationHelicopterRequests'
import OrganizationHelicopter from '@/models/OrganizationHelicopter'
import UIHelper from '@/helpers/ui'

export default {
  name: 'OrganizationHelicopters',
  components: {
    AppDataTable,
    SideBarOrganizationHelicopter,
  },

  mixins: [UIHelper],
  data() {
    return {
      organizationHelicoptersFields: [
        { key: 'modelType', label: this.$t('organization_helicopter.model_type'), sortable: true },
        { key: 'registration', label: this.$t('organization_helicopter.registration'), sortable: true },
        { key: 'callSign', label: this.$t('organization_helicopter.call_sign'), sortable: true },
        { key: 'aoc', label: this.$t('organization_helicopter.aoc'), sortable: true },
        { key: 'description', label: this.$t('organization_helicopter.description'), sortable: true },
        { key: 'active', label: this.$t('common.status'), sortable: true },
      ],
      sort: {
        sortBy: '',
        sortDesc: '',
      },
      organizationHelicopterData: new OrganizationHelicopter(),
      organizationHelicopterMode: null,
      organization: null,
    }
  },
  computed: {
    ...mapState('auth', ['selectedOrganization']),
    ...mapState('organizationHelicopter', ['organizationHelicopters', 'organizationHelicoptersLoading', 'pagination', 'queryParams', 'sorting']),
    ...mapState('appConfig', ['dataTable']),
    ...mapFields('organizationHelicopter', [
      'queryParams.page',
      'queryParams.numberOfItemsPerPage',
      'queryParams.modelTypeOrder',
      'queryParams.registrationOrder',
      'queryParams.callSignOrder',
      'queryParams.aocOrder',
      'queryParams.descriptionOrder',
      'queryParams.statusOrder',
      'search.searchQuery',
    ]),
    search: {
      get() {
        return this.searchText
      },
      set(value) {
        this.setSearch(value)
      },
    },
  },
  watch: {
    queryParams: {
      handler() {
        this.fetchOrganizationHelicopters(true)
      },
      deep: true,
    },

    sort: {
      handler(value) {
        this.setSort(value)
      },
      deep: true,
    },
  },
  mounted() {
    if (this.$route.params.refresh) {
      this.fetchOrganizationHelicopters(this.$route.params.refresh)
    } else {
      this.fetchOrganizationHelicopters()
    }
  },
  methods: {
    ...mapActions('organizationHelicopter', ['fetchOrganizationHelicopters', 'clearOrganizationHelicoptersShowed', 'setSort', 'setSearch']),

    deleteOrganizationHelicopter(helicopter) {
      deleteOrganizationHelicopterRequest(this.selectedOrganization.id, helicopter.id).then(response => {
        response && this.fetchOrganizationHelicopters(true)
      })
    },

    changeTypeForm(type, helicopter) {
      if (helicopter) {
        this.organizationHelicopterData = new OrganizationHelicopter(helicopter)
        if (type === 'duplicate') {
          this.organizationHelicopterData.id = null
          this.organizationHelicopterData.registration = ''
          this.organizationHelicopterData.aoc = ''
        }

        this.organizationHelicopterData.description = helicopter.description ?? ''
        this.organizationHelicopterData.cockpitConfiguration = helicopter.cockpitConfiguration ?? ''
        this.organizationHelicopterData.urlCalendar = helicopter.urlCalendar ?? ''
      } else if (type === 'add') {
        this.organizationHelicopterData = new OrganizationHelicopter()
      }
      this.organizationHelicopterMode = type
    },
  },

  beforeRouteLeave(to, from, next) {
    this.clearOrganizationHelicoptersShowed()
    next()
  },
}
</script>
