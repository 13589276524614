/* eslint-disable class-methods-use-this */

import BaseModel from './_Base'

/**
 * Organization
 * @link https://vuemc.io/#basic-usage
 */
class OrganizationHelicopter extends BaseModel {
  // Default attributes that define the "empty" state.
  defaults() {
    return {
      id: null,
      modelType: '',
      description: '',
      registration: '',
      callSign: '',
      aoc: '',
      manufacturer: '',
      cockpitConfiguration: '',
      urlCalendar: '',
      active: true,
    }
  }

  // Attribute mutations to normalize data.
  mutations() {
    return {
      id: id => id || null,
      modelType: String,
      description: String,
      registration: String,
      callSign: String,
      aoc: String,
      manufacturer: String,
      cockpitConfiguration: String,
      urlCalendar: String,
      active: Boolean,
    }
  }

  // ? NOTE: Which validation library to choose?
  // Attribute validation, acts after mutations

  // TODO
  // validation() {
  //   return {

  //   }
  // }

  //--------------------------------
  //        API REQUESTS
  //--------------------------------
}

export default OrganizationHelicopter
