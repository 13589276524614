<template>
  <app-sidebar-form
    id="sidebar-organization-helicopter"
    :edition="!!value"
    :title="$t('organization_helicopter.side_bar.title')"
    :edition-mode-title="value"
    :entity="organizationHelicopter"
    :external-request="submitOrganizationHelicopter"
    form-name="sidebar-organization-helicopter"
    @update:edition="val => {
      if (!val) $emit('input', null)
    }"
  >
    <template #fields="{ item }">
      <!-- MODEL TYPE -->
      <app-select
        id="helicopterModelType"
        v-model="item.modelType"
        :options="aircrafts"
        :label-field="$t('organization_helicopter.model_type')"
        push-tags
        taggable
        required
        label="name"
        v-bind="aircraftsMetaData"
        :clearable="false"
        paginated
        :searching="aircraftsLoading"
        :overlay="aircraftsLoading"
        @option:selected="changeSelectedAircraft($event)"
        @click:previous-page="
          () => {
            setPage(aircraftsMetaData.previousPage)
            fetchAircrafts()
          }
        "
        @click:next-page="
          () => {
            setPage(aircraftsMetaData.nextPage)
            fetchAircrafts()
          }
        "
        @search="
          search => {
            setPage(1)
            setName(search)
            fetchAircrafts()
          }
        "
      />
      <!-- DESCRIPTION -->
      <validation-provider #default="{ errors }" :name="$t('organization_helicopter.description')">
        <b-form-group label-for="helicopterDescription" :label="$t('organization_helicopter.description')">
          <b-form-textarea
            id="helicopterDescription"
            v-model="item.description"
            rows="5"
            :state="errors.length > 0 ? false : null"
            :placeholder="$t('organization_helicopter.description')"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </b-form-group>
      </validation-provider>

      <!-- REGISTRATION-->
      <app-input
        id="helicopterRegistration"
        v-model="item.registration"
        :label="$t('organization_helicopter.registration')"
        required
      />

      <!-- CALL SIGN -->
      <app-input
        id="helicopterCallSign"
        v-model="item.callSign"
        :label="$t('organization_helicopter.call_sign')"
        required
      />

      <!-- AOC -->
      <app-input
        id="helicopteraoc"
        v-model="item.aoc"
        :label="$t('organization_helicopter.aoc')"
      />

      <!-- MANUFACTURER -->
      <app-input
        id="helicopterManufacturer"
        v-model="item.manufacturer"
        :label="$t('organization_helicopter.manufacturer')"
        required
      />

      <!-- COCKPIT -->
      <enum-select
        id="helicpopterCockpit"
        v-model="item.cockpitConfiguration"
        :label="$t('organization_helicopter.cockpit')"
        enum-class="CockpitConfiguration"
      />

      <!-- URL CALENDAR -->
      <app-input
        id="helicopterUrlCalendar"
        v-model="item.urlCalendar"
        :label="$t('organization_helicopter.url_calendar')"
      />

      <!-- ACTIVE -->
      <validation-provider #default="{ errors }" :name="$t('common.active')">
        <b-form-group class="d-flex" :label="$t('common.active')" label-for="helicopterActive" :state="errors.length > 0 ? false : null">
          <b-form-checkbox
            id="helicopterActive"
            v-model="item.active"
            class="pl-1"
            name="check-button"
            switch
            inline
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </b-form-group>
      </validation-provider>
    </template>
  </app-sidebar-form>
</template>

<script>
import { union } from 'lodash'
import { mapActions, mapState } from 'vuex'

import {
  patchOrganizationHelicopterRequest,
  postOrganizationHelicopterRequest,
} from '@/request/globalApi/requests/organizationHelicopterRequests'
import AppSidebarForm from '@/components/AppSidebarForm.vue'
import AppSelect from '@/components/AppSelect.vue'
import OrganizationHelicopter from '@/models/OrganizationHelicopter'

export default {
  name: 'SidebarOrganizationHelicopter',

  components: {
    AppSelect,
    AppSidebarForm,
  },

  props: {
    value: {
      type: String,
      default: null,
    },
    organizationHelicopter: {
      type: Object,
      default: () => new OrganizationHelicopter(),
    },
    organizationId: {
      type: Number,
      default: null,
      required: true,
    },
  },

  computed: {
    ...mapState('aircraft', ['aircraftsLoading', 'aircrafts', 'aircraftsMetaData']),
    computedAircraftsOptions() {
      const customOption = this.organizationHelicopter.modelType ? [this.organizationHelicopter.modelType] : []
      return union(customOption, this.aircrafts.map(aircraft => aircraft.name))
    },
  },

  watch: {
    value() {
      this.fetchAircrafts()
    },
  },

  methods: {
    ...mapActions('aircraft', ['fetchAircrafts', 'setPage', 'setName']),

    async submitOrganizationHelicopter(organizationHelicopter) {
      const action = organizationHelicopter.id
        ? patchOrganizationHelicopterRequest
        : postOrganizationHelicopterRequest

      action(this.organizationId, { ...organizationHelicopter, modelType: organizationHelicopter.modelType.name }).then(() => {
        this.$emit('input', null)
        this.$emit('refresh-organization-helicopters')
      })
    },
  },
}
</script>
