var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{staticClass:"mb-0",attrs:{"no-body":""}},[_c('app-data-table',{attrs:{"items":_vm.organizationHelicopters,"fields":_vm.organizationHelicoptersFields,"busy":_vm.organizationHelicoptersLoading,"sort-by":_vm.sort.sortBy,"sort-desc":_vm.sort.sortDesc,"per-page":_vm.numberOfItemsPerPage,"small":_vm.$store.getters['app/lgAndDown'],"top-table":{
        showAdd: _vm.$can('ORGANIZATION_ACCOUNT_HELICOPTER_ADD'),
        showSearch: _vm.$can('ORGANIZATION_ACCOUNT_HELICOPTER_VIEW'),
        searchText: _vm.searchQuery,
      },"actions-table":{
        showDuplicate: _vm.$can('ORGANIZATION_ACCOUNT_HELICOPTER_ADD'),
        showEdit: _vm.$can('ORGANIZATION_ACCOUNT_HELICOPTER_EDIT'),
        showDelete: _vm.$can('ORGANIZATION_ACCOUNT_HELICOPTER_DELETE'),
      },"bottom-table":{ totalCount: _vm.pagination.totalItems }},on:{"update:sortBy":function($event){return _vm.$set(_vm.sort, "sortBy", $event)},"update:sort-by":function($event){return _vm.$set(_vm.sort, "sortBy", $event)},"update:sortDesc":function($event){return _vm.$set(_vm.sort, "sortDesc", $event)},"update:sort-desc":function($event){return _vm.$set(_vm.sort, "sortDesc", $event)},"table-search-text":function($event){;(_vm.searchQuery = $event), _vm.fetchOrganizationHelicopters(true)},"table-add":function($event){return _vm.changeTypeForm('add')},"edit":function($event){return _vm.changeTypeForm('edit', $event.item)},"duplicate":function($event){return _vm.changeTypeForm('duplicate', $event.item)},"delete":function($event){return _vm.deleteOrganizationHelicopter($event.item)}},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle"})],1)]},proxy:true},{key:"cell(active)",fn:function(data){return [(data.item.active)?_c('b-badge',{attrs:{"pill":"","variant":"light-success"}},[_vm._v(" "+_vm._s(_vm.$t('common.active'))+" ")]):_c('b-badge',{attrs:{"pill":"","variant":"light-danger"}},[_vm._v(" "+_vm._s(_vm.$t('common.inactive'))+" ")])]}}])}),_c('side-bar-organization-helicopter',{attrs:{"organization-helicopter":_vm.organizationHelicopterData._attributes,"organization-id":_vm.selectedOrganization.id},on:{"refresh-organization-helicopters":function($event){return _vm.fetchOrganizationHelicopters(true)}},model:{value:(_vm.organizationHelicopterMode),callback:function ($$v) {_vm.organizationHelicopterMode=$$v},expression:"organizationHelicopterMode"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }